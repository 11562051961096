import React, { useEffect, useRef } from 'react';
import { Loading } from '@src/components';
import { UserType } from '@src/types/user';
import { createContext, useCallback, useMemo, useState } from 'react';
import { ASYNC_STORAGE_KEYS } from '@src/constants/asyncStorageKeys';
import { useAsyncStorage } from '@src/hooks';
import jwt_decode from 'jwt-decode';
import type { JwtPayload } from 'jwt-decode';
interface IAuthContext {
  user: UserType | null;
  signIn: (username: string, password: string) => Promise<boolean>;
  signOut: () => void;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export interface AuthContextProviderProps {
  children: React.ReactNode;
}

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const { setItem, getItem, removeItem } = useAsyncStorage();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userStorage, setUserStorage] = useState<UserType>(null);
  const bootstrapUserRef = useRef(false);

  useEffect(() => {
    if (!bootstrapUserRef.current) {
      const getStorageUser = async () => {
        setIsLoading(true);
        const userDataStorage = await getItem(ASYNC_STORAGE_KEYS.USER);
        if (userDataStorage != null) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const user: UserType = await JSON.parse(userDataStorage);
          setUserStorage(user);
        }
        if (!userStorage) {
          const tokenStorage = await getItem(ASYNC_STORAGE_KEYS.USER_TOKEN);
          let val: UserType = null;
          if (tokenStorage != undefined) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            val = await JSON.parse(tokenStorage);
          }
          if (val) {
            setUserStorage(val);
            await setItem(ASYNC_STORAGE_KEYS.USER, JSON.stringify(val));
          }
        }

        setIsLoading(false);
      };
      void getStorageUser();
      bootstrapUserRef.current = true;
    }
  }, [getItem, setItem, userStorage]);

  const signIn = useCallback(
    async (username: string, password: string) => {
      const users: UserType[] = [
        {
          id: '1234567890',
          name: 'Exemplo',
          email: 'jacksonbicalho@gmail.com',
          surname: 'da Silva',
          username: 'exemplo',
          password: '123456',
        },
      ];
      setIsLoading(true);
      const token: string = await new Promise((resolve) => {
        setTimeout(() => {
          resolve(
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwiaXNzIjoiUk5MQUIiLCJpYXQiOjE1MTYyMzkwMjIsImV4cCI6MTkyNDk5MTk5OX0.Izlkf6kqcNXMPuFOU9ZGfhuxp2f4Z8iIbNUajWPWGFQ',
          );
        }, 2000);
      });
      type JwtPayloadType = JwtPayload;
      const decodeToken: JwtPayloadType = await jwt_decode(token);

      const result: UserType[] = users.filter((user: UserType) => {
        const sub = decodeToken.sub;
        return user?.id === sub;
      });

      const usernameBD = result[0]?.username;
      const passwordBD = result[0]?.password;

      if (username != usernameBD || passwordBD != password) {
        setIsLoading(false);
        return false;
      }

      if (result) {
        setUserStorage(result[0]);
        void setItem(ASYNC_STORAGE_KEYS.USER, JSON.stringify(result[0]));
      }
      setIsLoading(false);
      return true;
    },
    [setItem],
  );

  const signOut = useCallback(async () => {
    setIsLoading(true);
    await removeItem(ASYNC_STORAGE_KEYS.USER);
    await removeItem(ASYNC_STORAGE_KEYS.COLOR_MODE);
    setUserStorage(null);
    setIsLoading(false);
    return true;
  }, [removeItem]);

  const user: UserType = useMemo(() => userStorage ?? null, [userStorage]);

  const value = useMemo(() => {
    return {
      user,
      signIn,
      signOut,
    };
  }, [signIn, signOut, user]);
  if (isLoading) return <Loading />;
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
