import React from 'react';
import { Text as TextDefault, TextProps, StyleSheet } from 'react-native';
import { PpropertiesType, TypographyTextType } from '@src/themes/typography';
import { useTypography } from '@src/hooks/useTypography';
import { useColorMode } from '@src/hooks';

interface IPropsText extends TextProps {
  type?: TypographyTextType;
}
export type PropsText = IPropsText;

export const Text = ({ type, ...props }: PropsText) => {
  const { colors } = useColorMode();
  const { typography, typographySize } = useTypography();
  const textType = type == undefined ? 'body' : type;
  const textSize = typographySize ?? 'medium';
  const styleCompose: PpropertiesType = typography[textType][textSize];
  const styles = StyleSheet.create({
    text: {
      ...styleCompose,
      color: colors.onBackground,
    },
  });

  return (
    <TextDefault selectable={true} {...props} style={[styles.text, props.style]}>
      {props.children}
    </TextDefault>
  );
};
