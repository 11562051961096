import React from 'react';
import { View, Text, Button, StyleSheet } from 'react-native';

type Props = NotFoundScreenProps;
export const NotFoundScreen = (props: Props) => {
  const { navigate } = props.navigation;

  return (
    <View style={styles.container}>
      <Text>NotFound</Text>
      <Button onPress={() => navigate('Home')} title="Home" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
