import React from 'react';
import { useColorMode } from '@src/hooks';
import { TextInput as TextInputDefault, StyleSheet } from 'react-native';
import type {
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  TextInputProps,
} from 'react-native';
import { View } from '../View';
import { Text } from '../Text';

export interface IProps extends TextInputProps {
  errorText?: string;
  label?: string;
  onSubmit?: () => void;
}

export type Props = IProps;
type Event = NativeSyntheticEvent<TextInputKeyPressEventData>;
const handleOnKeyPress = ({ nativeEvent }: Event, onSubmit?: () => void) => {
  const key = nativeEvent.key;
  if (key === 'Enter') {
    if (onSubmit) {
      void onSubmit();
    }
  }
};

export const TextInput = ({ errorText, label, ...props }: Props) => {
  const { colors } = useColorMode();
  const styles = StyleSheet.create({
    container: {
      width: '100%',
      marginVertical: 12,
    },
    input: {
      backgroundColor: colors.surface,
    },
    label: {
      fontWeight: 'bold',
    },
    error: {
      fontSize: 14,
      color: colors.error,
      paddingHorizontal: 4,
      paddingTop: 4,
    },
  });

  return (
    <View style={styles.container}>
      {label ? (
        <Text type={'label'} style={styles.label}>
          {label}
        </Text>
      ) : null}
      <TextInputDefault
        style={{ ...styles.input }}
        selectionColor={colors.primary}
        underlineColorAndroid={'transparent'}
        inputMode={'text'}
        onKeyPress={(event) => handleOnKeyPress(event, props.onSubmit)}
        {...props}
      />
      {errorText ? <Text style={styles.error}>{errorText}</Text> : null}
    </View>
  );
};
