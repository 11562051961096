import React from 'react';
import { View, Text } from '@src/components/core';
import { StyleSheet, Dimensions } from 'react-native';
import { useColorMode, useTypography } from '@src/hooks';
import { makeKey } from '@src/utils';

export type RenderItemProps = {
  id: string;
  title: string | number | JSX.Element;
  content: string | number | JSX.Element;
};

export const RenderItem = ({ id, title, content }: RenderItemProps) => {
  const { typography, typographySize } = useTypography();
  const { colors } = useColorMode();
  let textSize = typographySize ?? 'medium';
  const { width: SCREEN_WIDTH } = Dimensions.get('window');
  const scale = SCREEN_WIDTH / 320;
  if (scale <= 2) {
    textSize = 'small';
  }
  return (
    <View
      key={id}
      style={[styles.row, { backgroundColor: colors.outline, borderColor: colors.outlineVariant }]}
    >
      <View
        key={makeKey(10)}
        style={[
          styles.column,
          { alignItems: 'flex-start', flexGrow: 1, backgroundColor: colors.outline },
        ]}
      >
        <Text
          key={makeKey(10)}
          type={'title'}
          style={[typography.title[textSize], { color: colors.outlineVariant }]}
        >
          {title}:
        </Text>
      </View>
      <View
        key={makeKey(10)}
        style={[
          styles.column,
          { alignSelf: 'flex-start', flexGrow: 2, backgroundColor: colors.outline },
        ]}
      >
        {content}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
  },
  column: {
    flex: 1,
    flexDirection: 'column',
    padding: 10,
  },
});
