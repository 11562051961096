import React, { useCallback } from 'react';
import { View, Text } from '@src/components/core';
import { FlatList, Dimensions, Linking, TouchableOpacity } from 'react-native';
import { appInfo } from '@src/constants/info';
import { useColorMode, useTypography } from '@src/hooks';
import { makeKey } from '@src/utils';
import { FontAwesome } from '@expo/vector-icons';
import { RenderItemProps, RenderItem, ColorPalette, LastUpdates } from '@src/components';
import { layoutStyle } from '@src/styles';
import { ScrollView } from 'react-native-gesture-handler';

type OpenURLButtonProps = {
  url: string;
  children: string | JSX.Element;
};

const supportedURL = 'https://github.com/jacksonbicalho/react-native-lab';
const OpenURLButton = ({ url, children }: OpenURLButtonProps) => {
  const handlePress = useCallback(() => {
    void Linking.openURL(url);
  }, [url]);

  return (
    <TouchableOpacity activeOpacity={1} onPress={handlePress}>
      {children}
    </TouchableOpacity>
  );
};

export const AboutScreen = () => {
  const { colors } = useColorMode();
  const { typography, typographySize } = useTypography();
  let textSize = typographySize ?? 'medium';
  const { width: SCREEN_WIDTH } = Dimensions.get('window');
  const scale = SCREEN_WIDTH / 320;
  if (scale <= 2) {
    textSize = 'small';
  }

  const renderText = (content: string) => {
    return (
      <Text
        key={makeKey(10)}
        type={'title'}
        style={[typography.title[textSize], { color: colors.outlineVariant }]}
      >
        {content}
      </Text>
    );
  };

  const DATA: RenderItemProps[] = [
    {
      id: makeKey(10),
      title: 'Author',
      content: renderText('Jackson Bicalho'),
    },
    {
      id: makeKey(10),
      title: 'Repositório',
      content: (
        <View style={{ width: 24 }}>
          <OpenURLButton url={supportedURL}>
            {
              <FontAwesome
                name="github"
                size={32}
                color={colors.outlineVariant}
                style={{ backgroundColor: colors.outline }}
              />
            }
          </OpenURLButton>
        </View>
      ),
    },
    {
      id: makeKey(10),
      title: 'Version',
      content: renderText(appInfo.version),
    },
    {
      id: makeKey(10),
      title: 'Licence',
      content: renderText(appInfo.licence),
    },
  ];
  return (
    <ScrollView>
      <View style={[layoutStyle.container, { paddingHorizontal: 1 }]} key={makeKey(10)}>
        <View style={layoutStyle.content} key={makeKey(10)}>
          <Text
            style={[
              layoutStyle.headline,
              { paddingHorizontal: layoutStyle.container.paddingHorizontal },
            ]}
            type={'headline'}
          >
            Sobre
          </Text>
          <FlatList
            key={makeKey(10)}
            data={DATA}
            renderItem={({ item }) => (
              <RenderItem content={item.content} id={item.id} title={item.title} />
            )}
            keyExtractor={(_item, index) => index.toString()}
          />
        </View>
        <View style={[{ marginTop: 20 }]} key={makeKey(10)}>
          <Text
            style={[
              layoutStyle.headline,
              { paddingHorizontal: layoutStyle.container.paddingHorizontal },
            ]}
            type={'headline'}
          >
            Paleta de cores
          </Text>

          <ColorPalette />
        </View>
        <View style={[{ marginTop: 20 }]} key={makeKey(10)}>
          <Text
            style={[
              layoutStyle.headline,
              { paddingHorizontal: layoutStyle.container.paddingHorizontal },
            ]}
            type={'headline'}
          >
            Ultimas alterações
          </Text>

          <LastUpdates />
        </View>
      </View>
    </ScrollView>
  );
};
