import { useColorMode } from '@src/hooks';
import React from 'react';
import { View as ViewDefault, StyleSheet, ViewProps } from 'react-native';

type Props = ViewProps;

export const View = (props: Props) => {
  const { colors } = useColorMode();
  const styles = StyleSheet.create({
    container: {
      backgroundColor: colors.background,
    },
  });

  return (
    <ViewDefault {...props} style={[styles.container, props.style]}>
      {props.children}
    </ViewDefault>
  );
};
