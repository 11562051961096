import React from 'react';
import { FlatList, Dimensions } from 'react-native';
import { View, Text, RenderItem } from '@src/components';
import type { RenderItemProps } from '@src/components';
import { appInfo } from '@src/constants/info';
import { useColorMode, useTypography } from '@src/hooks';
import { makeKey } from '@src/utils';

export const LastUpdates = () => {
  const { colors } = useColorMode();
  const { typography, typographySize } = useTypography();
  let textSize = typographySize ?? 'medium';
  const { width: SCREEN_WIDTH } = Dimensions.get('window');
  const scale = SCREEN_WIDTH / 320;
  if (scale <= 2) {
    textSize = 'small';
  }

  const renderText = (content: string) => {
    return (
      <Text
        key={makeKey(10)}
        type={'title'}
        style={[typography.title[textSize], { color: colors.outlineVariant }]}
      >
        {content}
      </Text>
    );
  };

  const DATA: RenderItemProps[] = [];

  appInfo.changes.map((change) => {
    const obj = {
      id: makeKey(10),
      title: change.message,
      content: (
        <View id={makeKey(10)} style={{ backgroundColor: colors.outline }}>
          {renderText(change.date)}
          {renderText(change.author)}
          {renderText(change.commit)}
        </View>
      ),
    };
    DATA.push(obj as unknown as RenderItemProps);
  });

  return (
    <FlatList
      key={makeKey(10)}
      data={DATA}
      renderItem={({ item }) => (
        <RenderItem content={item.content} id={item.id} title={item.title} />
      )}
      keyExtractor={(_item, index) => index.toString()}
    />
  );
};
