import React from 'react';
import { ButtonDarkMode, Text, View } from '@src/components/core';
import { useAuth } from '@src/hooks/useAuth';
import { StyleSheet } from 'react-native';
import { layoutStyle } from '@src/styles';
import { useColorMode } from '@src/hooks';
export const AccountScreen = () => {
  const { user } = useAuth();
  const { colorMode } = useColorMode();
  return (
    <View style={layoutStyle.container}>
      <View style={layoutStyle.content}>
        <Text style={layoutStyle.headline} type={'headline'}>
          Conta
        </Text>
        <Text style={styles.title} type={'title'}>
          Nome: {user?.name}
        </Text>
        <Text style={styles.title} type={'title'}>
          Sobre nome: {user?.surname}
        </Text>
        <Text style={styles.title} type={'title'}>
          Email: {user?.email}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    textAlign: 'left',
  },
});
