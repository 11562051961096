import { StyleSheet } from 'react-native';
export const styles = StyleSheet.create({
  label: {
    flexDirection: 'row',
    marginLeft: 10,
    backgroundColor: 'transparent',
  },
  checkbox: {
    marginRight: 10,
  },
});
