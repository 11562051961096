/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react';
import { StyleSheet, Pressable, Image, PixelRatio, ImageSourcePropType } from 'react-native';
import { Text, TextInput, View } from '@src/components/core';
import { useColorMode } from '@src/hooks';
import { useAuth } from '@src/hooks/useAuth';
import appLogoWhite from '@assets/images/app-logo-white.png';
import appLogo from '@assets/images/app-logo.png';
import { appInfo } from '@src/constants/info';
import { LAYOUT } from '@src/constants/layout';

type Props = SignInScreenProps;
export const SignInScreen = ({ navigation }: Props) => {
  const { signIn } = useAuth();
  const [isHovered, setIsHovered] = useState(false);
  const { colors, colorMode } = useColorMode();
  const [username, onChangeUsename] = useState('');
  const [password, onChangePassword] = useState('');

  const onLogin = async () => {
    const login = await signIn(username, password);
    if (!login) {
      alert('usuário ou senha incorretos');
    }
  };

  const onSignUp = () => {
    void navigation.navigate('SignUp');
  };

  const appLogoUse: ImageSourcePropType = colorMode == 'dark' ? appLogoWhite : appLogo;

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.imageContainer}>
          <Image source={appLogoUse} style={styles.appLogo} />
        </View>
        <View>
          <Text type={'title'} style={styles.appName}>
            {appInfo.name}
          </Text>
        </View>
      </View>
      <View style={styles.form}>
        <View style={styles.formHeader}>
          <Text type={'headline'} style={styles.title}>
            Sign in
          </Text>
        </View>
        <View style={styles.formContent}>
          <TextInput
            label={'Username or email address  (use: exemplo)'}
            value={username}
            onChangeText={onChangeUsename}
            onSubmit={() => onLogin}
            style={[styles.input, { marginTop: 2 }]}
            autoFocus={true}
          />
          <TextInput
            label={'Password  (use: 123456)'}
            value={password}
            onChangeText={onChangePassword}
            onSubmit={() => onLogin}
            secureTextEntry={true}
            style={styles.input}
          />
          <Pressable
            onHoverIn={() => setIsHovered(true)}
            onHoverOut={() => setIsHovered(false)}
            onPress={onLogin}
          >
            <View
              style={[
                styles.buttonSubmit,
                {
                  backgroundColor: isHovered ? colors.primary : colors.inversePrimary,
                  alignItems: 'center',
                },
              ]}
            >
              <Text style={{ color: isHovered ? colors.onPrimary : colors.onPrimaryContainer }}>
                Entrar
              </Text>
            </View>
          </Pressable>
          <Pressable style={[styles.linkSignUp]} onPress={onSignUp}>
            <Text type={'title'} style={[{ color: colors.primary }]}>
              Ainda não tem conta? Clique aqui para criar a sua
            </Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

const SIZE_APP_LOGO = 60;
const IMAGE_CONTAINER_SIZE = PixelRatio.getPixelSizeForLayoutSize(SIZE_APP_LOGO);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingHorizontal: LAYOUT.CONTAINER.PADDING_HORIZONTAL,
  },
  scrollView: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
  },
  imageContainer: {
    width: IMAGE_CONTAINER_SIZE,
    height: IMAGE_CONTAINER_SIZE,
    marginTop: 20,
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  appLogo: {
    flex: 1,
    aspectRatio: 1 * 1.4,
    width: IMAGE_CONTAINER_SIZE,
    height: IMAGE_CONTAINER_SIZE,
  },
  appName: {
    fontSize: 22,
    textAlign: 'center',
    marginTop: 5,
  },
  form: {
    flex: 1,
    alignSelf: 'center',
  },
  formHeader: {
    marginTop: 20,
    marginBottom: 0,
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    paddingBottom: 0,
  },
  formContent: {
    // flex: 2,
    padding: 40,
    paddingTop: 0,
  },
  title: {
    margin: 0,
  },
  input: {
    width: '100%',
    height: 44,
    padding: 10,
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 5,
    marginBottom: 10,
    marginTop: 5,
  },
  buttonSubmit: {
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    width: '100%',
    height: 44,
    borderRadius: 5,
  },
  linkSignUp: {
    marginVertical: 10,
  },
});
