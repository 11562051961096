import React from 'react';
import { useLinkProps } from '@react-navigation/native';
import { Platform, TouchableOpacity, View, Text, GestureResponderEvent } from 'react-native';
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';

type PropsLinkButton = {
  to: To<RootStackParamList>;
  action:
    | Readonly<{
        type: string;
        payload?: object | undefined;
        source?: string | undefined;
        target?: string | undefined;
      }>
    | undefined;
  children: string;
  rest?: {
    [x: string]: unknown;
  };
  onClick?: (
    e?: GestureResponderEvent | React.MouseEvent<HTMLAnchorElement, MouseEvent> | undefined,
  ) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

export const LinkButton = ({ to, action, children, ...rest }: PropsLinkButton) => {
  const { onPress, ...props } = useLinkProps({ to, action });

  const [isHovered, setIsHovered] = React.useState(false);

  if (Platform.OS === 'web') {
    // It's important to use a `View` or `Text` on web instead of `TouchableX`
    // Otherwise React Native for Web omits the `onClick` prop that's passed
    // You'll also need to pass `onPress` as `onClick` to the `View`
    // You can add hover effects using `onMouseEnter` and `onMouseLeave`
    return (
      <View
        onClick={onPress}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ opacity: isHovered ? 0.5 : 1 }}
        {...props}
        {...rest}
      >
        <Text>
          <i className="fa fa-home"></i> {children}
        </Text>
      </View>
    );
  }

  return (
    <TouchableOpacity onPress={onPress} {...props} {...rest}>
      <Text>{children}</Text>
    </TouchableOpacity>
  );
};
