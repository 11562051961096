import React from 'react';
import { FlatList } from 'react-native';
import { View, Text, RenderItem } from '@src/components';
import { useColorMode } from '@src/hooks';
import { makeKey } from '@src/utils';
import type { RenderItemProps } from '@src/components';

export const ColorPalette = () => {
  const { colors } = useColorMode();

  const DATA: RenderItemProps[] = [];

  Object.entries(colors).map((color) => {
    const obj = {
      id: makeKey(10),
      title: color[0],
      content: (
        <View
          style={{ backgroundColor: color[1], borderColor: '#000000', borderWidth: 1 }}
          id={makeKey(10)}
        >
          <Text
            style={{
              textAlign: 'center',
              color: color[1] == '#ba1a1a' ? '#ffffff' : 'red',
            }}
          >
            {color[1]}
          </Text>
        </View>
      ),
    };
    DATA.push(obj as RenderItemProps);
  });
  return (
    <FlatList
      scrollEnabled={false}
      key={makeKey(10)}
      data={DATA}
      renderItem={({ item }) => (
        <RenderItem content={item.content} id={item.id} title={item.title} />
      )}
      keyExtractor={(_item, index) => index.toString()}
    />
  );
};
