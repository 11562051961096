import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { NotFoundScreen } from '@src/screens';
import { HeaderRight, LogoTitle, View } from '@src/components';
import { useColorMode, useTypography } from '@src/hooks';
import { PpropertiesType } from '@src/themes';
import { LoggedMenuStack } from './logged-menu.stack';
import { appInfo } from '@src/constants/info';
import { LinearGradient } from 'expo-linear-gradient';

const Stack = createNativeStackNavigator<RootStackParamList>();
export const AppStack = () => {
  const { typography, typographySize } = useTypography();
  const textSize = typographySize ?? 'medium';
  const styleCompose: PpropertiesType = typography.headline[textSize];
  const titleDefault: object | undefined | string =
    appInfo.name !== 'undefined' ? appInfo.name : '';
  const { colors } = useColorMode();
  return (
    <Stack.Navigator
      id={'APP_STACK'}
      initialRouteName={'home'}
      screenOptions={{
        headerBackground: () => (
          <View style={{ backgroundColor: colors.outlineVariant, flex: 1 }}>
            <LinearGradient
              // Background Linear Gradient
              colors={[colors.outline, 'transparent']}
              style={{ backgroundColor: colors.outlineVariant, flex: 1 }}
            />
          </View>
        ),
        headerTitle: (props) => <LogoTitle title={titleDefault} {...props} />,
        headerShown: true,
        headerRight: () => <HeaderRight />,
        headerBackVisible: true,
        headerTitleStyle: {
          ...styleCompose,
        },
        headerTransparent: false,
        presentation: 'modal',
      }}
    >
      <Stack.Screen name="LoggedMenu" component={LoggedMenuStack} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} />
    </Stack.Navigator>
  );
};
