import React from 'react';
import { Image, StyleSheet } from 'react-native';
import { View } from '@src/components';

import { createHash } from 'sha256-uint8array';

type GravatarProps = {
  email: string;
  size: number;
  cache?: number;
};

export const Gravatar = ({ email, size, cache }: GravatarProps) => {
  const emailHash = createHash().update(email).digest('hex');
  const _cache = cache ? cache : 60000;
  return (
    <View style={styles.container}>
      <Image
        style={{
          borderRadius: 40,
          shadowRadius: 5,
          alignSelf: 'center',
          borderWidth: 0,
          width: size,
          height: size,
          aspectRatio: 10,
        }}
        source={{
          uri: `https://gravatar.com/avatar/${emailHash}?size=${size}&cache=${_cache}`,
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 0,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'transparent',
    marginRight: 10,
  },
});
