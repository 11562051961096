/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react';
import { StyleSheet, Modal, Pressable, Image, PixelRatio, Alert } from 'react-native';
import { Text, TextInput, View } from '@src/components/core';
import { useColorMode } from '@src/hooks';
import appLogo from '@assets/images/app-logo.png';
import { LAYOUT } from '@src/constants/layout';

type Props = SignUpScreenProps;
export const SignUpScreen = ({ navigation }: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { colors } = useColorMode();
  const [email, onChangeEmail] = useState('');

  const onSignIn = () => {
    void navigation.navigate('SignIn');
  };

  const onSubmit = () => {
    Alert.alert('Enviar email', 'Enviar email');
  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.header}>
          <View style={styles.imageContainer}>
            <Image source={appLogo} style={styles.appLogo} />
          </View>
        </View>
        <View style={styles.form}>
          <View style={styles.formHeader}>
            <Text type={'headline'} style={styles.title}>
              Sign Up
            </Text>
          </View>
          <View style={styles.formContent}>
            <TextInput
              label={'Digite um email válido para começarmos'}
              value={email}
              onChangeText={onChangeEmail}
              style={styles.input}
              autoFocus={true}
              textContentType={'emailAddress'}
            />
            <Pressable
              onHoverIn={() => setIsHovered(true)}
              onHoverOut={() => setIsHovered(false)}
              onPress={onSubmit}
            >
              <View
                style={[
                  styles.buttonSubmit,
                  {
                    backgroundColor: isHovered ? colors.primary : colors.inversePrimary,
                    alignItems: 'center',
                  },
                ]}
              >
                <Text style={{ color: isHovered ? colors.onPrimary : colors.onPrimaryContainer }}>
                  Enviar email para continuar
                </Text>
              </View>
            </Pressable>
            <Pressable style={[styles.linkSignUp]} onPress={onSignIn}>
              <Text type={'title'} style={[{ color: colors.primary }]}>
                Já tem uma conta? Clique aqui para entrar
              </Text>
            </Pressable>
          </View>
        </View>
      </View>
      <Modal animationType="slide" transparent={true} visible={modalVisible}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text type={'headline'} style={styles.modalText}>
              Usuário ou senha incorretos!
            </Text>
            <Pressable
              style={[styles.button, styles.buttonClose]}
              onPress={() => setModalVisible(!modalVisible)}
            >
              <Text style={styles.textStyle}>Tentar novamente</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    </>
  );
};
const sizeAppLogo = 60;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingHorizontal: LAYOUT.CONTAINER.PADDING_HORIZONTAL,
  },
  header: {
    flex: 0.5,
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
  },
  imageContainer: {
    width: PixelRatio.getPixelSizeForLayoutSize(sizeAppLogo),
    height: PixelRatio.getPixelSizeForLayoutSize(sizeAppLogo),
    aspectRatio: 1 * 1.4,
  },
  appLogo: {
    resizeMode: 'cover',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  form: {
    flex: 2,
    alignSelf: 'center',
  },
  formHeader: {
    flex: 0.3,
    justifyContent: 'flex-end',
    alignContent: 'center',
    alignSelf: 'center',
    paddingBottom: 0,
  },
  formContent: {
    flex: 2,
    padding: 40,
    paddingTop: 10,
  },
  title: {},
  input: {
    width: '100%',
    height: 44,
    padding: 10,
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 5,
    marginBottom: 10,
    marginTop: 5,
  },
  buttonSubmit: {
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    width: '100%',
    height: 44,
    borderRadius: 5,
  },
  linkSignUp: {
    marginVertical: 10,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: '#fff',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
});
