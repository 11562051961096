export const ASYNC_STORAGE_KEYS = {
  NAVIGATION_STATE: '@navigation/navigation-state',
  COLOR_MODE: '@theme/colorMode',
  TOPOLOGY_SIZE: '@theme/topologySize',
  TOPOLOGY_SIZE_DEFAULT: 'medium',
  COLOR_MODE_DEFAULT: 'light',
  USER: '@user',
  USER_TOKEN: '@user/token',
  USER_LANGUAGE: '@language/user-language',
} as const;
