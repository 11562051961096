import React, { useState } from 'react';
import { DrawerActions, NavigationProp, useNavigation } from '@react-navigation/native';
import { Gravatar, View } from '@src/components';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useAuth } from '@src/hooks/useAuth';

export const HeaderRight = () => {
  const [imageOpacity, setImageOpacity] = useState<number>(100);
  const { dispatch } = useNavigation<NavigationProp<RootStackParamList>>();
  const { user } = useAuth();
  const email = user?.email ? user.email : '';

  const handleronPress = () => {
    dispatch(DrawerActions.toggleDrawer());
  };
  const handleronBlur = () => {
    setImageOpacity(imageOpacity == 100 ? 0.8 : 100);
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    <TouchableOpacity
      onPress={handleronPress}
      onFocus={handleronBlur}
      style={{ opacity: imageOpacity }}
    >
      <View style={styles.container}>
        <Gravatar email={email} size={30} />;
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'transparent',
    padding: 5,
  },
});
