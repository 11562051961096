import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Text } from 'react-native';
import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native';
import { wait } from '@src/utils/wait';
// import { NavigationTheme, NavigationThemeType } from '@src/themes/navigation';
import { ColorModeType, AppCollorsScheme, AppCollorsSchemeType } from '@src/themes/colors';
import { linking } from '@src/navigation/linking';
import { Loading } from '@src/components/Loading';
import { getData, storeData } from '@src/services/asyncStorageThemeManager';
import { ASYNC_STORAGE_KEYS } from '@src/constants/asyncStorageKeys';

export type isDarkThemeType = boolean | undefined;
export interface ThemeContextValue {
  // navigationTheme: NavigationThemeType;
  colors: AppCollorsSchemeType;
  colorMode: ColorModeType;
  toggleColorMode: () => void;
}

export const ThemeContext = React.createContext<ThemeContextValue>({} as ThemeContextValue);
export interface ThemeContextProviderProps {
  children: React.ReactNode;
}

export const ThemeContextProvider = ({ children }: ThemeContextProviderProps) => {
  const navigationRef = useNavigationContainerRef<RootStackParamList>();
  const [colorMode, setColorMode] = useState<ColorModeType>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const colorModeRef = useRef(false);

  useEffect(() => {
    if (!colorModeRef.current) {
      const getStorageTheme = async () => {
        setIsLoading(true);
        await wait(100);
        setIsLoading(false);
        const val = await getData(
          ASYNC_STORAGE_KEYS.COLOR_MODE,
          ASYNC_STORAGE_KEYS.COLOR_MODE_DEFAULT,
        );
        setColorMode(val as ColorModeType);
      };
      void getStorageTheme();

      colorModeRef.current = true;
    }
  }, []);

  useEffect(() => {
    if (colorModeRef.current) {
      const setStorageTheme = async () => {
        if (colorMode == null) {
          const val = await getData(
            ASYNC_STORAGE_KEYS.COLOR_MODE,
            ASYNC_STORAGE_KEYS.COLOR_MODE_DEFAULT,
          );
          setColorMode(val as never);
        } else {
          await storeData(ASYNC_STORAGE_KEYS.COLOR_MODE, colorMode);
        }
      };
      void setStorageTheme();
    }
  }, [colorMode]);

  const colors: AppCollorsSchemeType = useMemo(
    () => (colorMode == 'dark' ? AppCollorsScheme.dark : AppCollorsScheme.light),
    [colorMode],
  );

  // const navigationTheme: NavigationThemeType = useMemo(
  //   // () => (colorMode == 'dark' ? NavigationTheme.dark : NavigationTheme.light),
  //   () => {
  //     NavigationTheme;
  //   },
  //   [],
  // );

  // navigationTheme.colors.primary = colors.primary;
  // navigationTheme.colors.background = colors.onBackground;
  // navigationTheme.colors.card = colors.primary;
  // navigationTheme.colors.text = colors.onPrimary;
  // navigationTheme.colors.border = colors.outline;
  // navigationTheme.colors.notification = colors.error;

  const toggleColorMode = useCallback(() => {
    setColorMode(colorMode == 'dark' ? 'light' : 'dark');
  }, [colorMode]);

  const value = useMemo(() => {
    return {
      // navigationTheme,
      colors,
      colorMode,
      toggleColorMode,
    };
  }, [colorMode, colors, toggleColorMode]);

  if (isLoading) return <Loading />;

  return (
    <NavigationContainer
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      // onReady={initialState}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      // onStateChange={onStateChange}
      linking={linking}
      fallback={<Text>Loading...</Text>}
      ref={navigationRef}
      // theme={navigationTheme}
    >
      <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
    </NavigationContainer>
  );
};
