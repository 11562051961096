import AsyncStorage from '@react-native-async-storage/async-storage';

export const useAsyncStorage = () => {
  const setItem = async (key: string, value: string) => {
    try {
      await AsyncStorage.setItem(key, value);
    } catch (e) {
      // saving error
    }
  };

  const getItem = async (key: string) => {
    try {
      const value = await AsyncStorage.getItem(key);
      if (value !== null) {
        return value !== null ? value : '';
      }
    } catch (e) {
      // error reading value
    }
  };

  const removeItem = async (key: string) => {
    try {
      await AsyncStorage.removeItem(key);
    } catch (e) {
      // error reading value
    }
  };
  return { setItem, getItem, removeItem };
};
