import { argbFromHex, hexFromArgb, themeFromSourceColor } from '@material/material-color-utilities';
export type ColorThemeType = {
  primary: string;
  onPrimary: string;
  primaryContainer: string;
  onPrimaryContainer: string;
  secondary: string;
  onSecondary: string;
  secondaryContainer: string;
  onSecondaryContainer: string;
  tertiary: string;
  onTertiary: string;
  tertiaryContainer: string;
  onTertiaryContainer: string;
  error: string;
  onError: string;
  errorContainer: string;
  onErrorContainer: string;
  background: string;
  onBackground: string;
  surface: string;
  onSurface: string;
  surfaceVariant: string;
  onSurfaceVariant: string;
  outline: string;
  outlineVariant: string;
  shadow: string;
  scrim: string;
  inverseSurface: string;
  inverseOnSurface: string;
  inversePrimary: string;
};

const colors = themeFromSourceColor(argbFromHex('#ffffff'), [
  {
    name: 'custom-1',
    value: argbFromHex('#ffffff'),
    blend: true,
  },
]);

const light: ColorThemeType = {} as ColorThemeType;
Object.entries(colors.schemes.light.toJSON()).map((obj) => {
  const key = obj[0] as keyof ColorThemeType;
  const keyNumber: number = Number(obj[1]);
  const value = hexFromArgb(keyNumber);
  light[key] = value;
});

const dark: ColorThemeType = {} as ColorThemeType;
Object.entries(colors.schemes.dark.toJSON()).map((obj) => {
  const key = obj[0] as keyof ColorThemeType;
  const keyNumber: number = Number(obj[1]);
  const value = hexFromArgb(keyNumber);
  dark[key] = value;
});
export type ColorModeType = 'light' | 'dark' | null;
export type AppCollorsSchemeType = typeof light;
const AppCollorsScheme = {
  light: light,
  dark: dark,
};

export { AppCollorsScheme };
