import 'react-native-gesture-handler';
import React from 'react';
import { Navigator } from '@src/navigation';
import { AuthContextProvider, ThemeContextProvider, TypographyContextProvider } from './contexts';
import { StatusBar } from 'expo-status-bar';

export const App = () => (
  <ThemeContextProvider>
    <TypographyContextProvider>
      <AuthContextProvider>
        <Navigator />
      </AuthContextProvider>
    </TypographyContextProvider>
    <StatusBar />
  </ThemeContextProvider>
);
