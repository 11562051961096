import React, { useEffect, useMemo, useRef, useState } from 'react';
import { wait } from '@src/utils/wait';
import { Typography, TypographySizeKeyType, TypographyType } from '@src/themes/typography';
import { Loading } from '@src/components/Loading';
import { getData, storeData } from '@src/services/asyncStorageThemeManager';
import { ASYNC_STORAGE_KEYS } from '@src/constants/asyncStorageKeys';

export interface TypographyContextValue {
  typography: TypographyType;
  typographySize: TypographySizeKeyType;
  selectTypographySize: (size: TypographySizeKeyType) => void;
}

export const TypographyContext = React.createContext<TypographyContextValue>(
  {} as TypographyContextValue,
);
export interface TypographyContextProviderProps {
  children: React.ReactNode;
}

export const TypographyContextProvider = ({ children }: TypographyContextProviderProps) => {
  const [typographySize, setTypographySize] = useState<TypographySizeKeyType>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const selectTypographySize = (size: TypographySizeKeyType) => {
    setTypographySize(size);
  };
  const typographySizeRef = useRef(false);
  useEffect(() => {
    if (!typographySizeRef.current) {
      const getStorageTypographySize = async () => {
        setIsLoading(true);
        await wait(100);
        const val = await getData(
          ASYNC_STORAGE_KEYS.TOPOLOGY_SIZE,
          ASYNC_STORAGE_KEYS.TOPOLOGY_SIZE_DEFAULT,
        );
        setTypographySize(val as never);
        setIsLoading(false);
      };
      void getStorageTypographySize();
      typographySizeRef.current = true;
    }
  }, []);

  useEffect(() => {
    if (typographySizeRef.current) {
      const setStorageTypographySize = async () => {
        if (typographySize == null) {
          const val = await getData(
            ASYNC_STORAGE_KEYS.TOPOLOGY_SIZE,
            ASYNC_STORAGE_KEYS.TOPOLOGY_SIZE_DEFAULT,
          );
          setTypographySize(val as never);
        } else {
          await storeData(ASYNC_STORAGE_KEYS.TOPOLOGY_SIZE, typographySize);
        }
      };
      void setStorageTypographySize();
    }
  }, [typographySize]);

  const typography: TypographyType = Typography;

  const value = useMemo(() => {
    return {
      typography,
      typographySize,
      selectTypographySize,
    };
  }, [typography, typographySize]);

  if (isLoading) return <Loading />;

  return <TypographyContext.Provider value={value}>{children}</TypographyContext.Provider>;
};
