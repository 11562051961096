import React from 'react';
import { AppStack } from './app.stack';
import { AuthStack } from './auth.stack';
import { useAuth } from '@src/hooks/useAuth';

export const Navigator = () => {
  const { user } = useAuth();
  const logado = user?.id ? true : false;
  return logado ? <AppStack /> : <AuthStack />;
};
