/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import { createURL } from 'expo-linking';

const prefix = createURL('/');

// Visit https://reactnavigation.org/docs/configuring-links#playground to see more
export const linking: LinkingOptions<
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  ReactNavigation.RootParamList | ReactNavigation.LoggedMenuParamList
> = {
  prefixes: [prefix],
  config: {
    screens: {
      LoggedMenu: {
        // path: 'home',
        initialRouteName: 'Home' as never,
        screens: {
          Home: {
            path: '',
          },
          ColorPalette: {
            path: '/color-palette',
          },
          About: {
            path: '/about',
          },
        },
      },
      SignIn: {
        path: 'sign-in',
      },
      SignUp: {
        path: 'sign-up',
      },
      NotFound: {
        path: '*',
      },
    },
  },
};
