import React, { useEffect, useRef, useState } from 'react';
import { FlatList, SafeAreaView, ScrollView, StyleSheet } from 'react-native';
import { Checkbox, View, Text } from '@src/components/core';
import { useColorMode } from '@src/hooks/useColorMode';
import { useTypography } from '@src/hooks/useTypography';
import { wait, makeKey } from '@src/utils';
import { LAYOUT } from '@src/constants/layout';

interface ISetting {
  id: string;
  label: string;
  content: JSX.Element;
}

export type SettingType = ISetting;

type SyzesType = {
  size: string;
  checked: boolean;
};

export const AppearanceScreen = () => {
  const storageSizeRef = useRef(false);
  const { colors } = useColorMode();
  const colorsArray = Object.entries(colors);
  const { selectTypographySize, typographySize } = useTypography();
  const [sizes, setSizes] = useState<SyzesType[]>([
    { size: 'large', checked: typographySize == 'large' },
    { size: 'medium', checked: typographySize == 'medium' },
    { size: 'small', checked: typographySize == 'small' },
  ]);

  useEffect(() => {
    const getStorageSize = async () => {
      if (!storageSizeRef.current) {
        await wait(200);
        selectTypographySize(typographySize);
      }
    };
    void getStorageSize();
    storageSizeRef.current = true;
  }, [selectTypographySize, sizes, typographySize]);

  const handleChange = (index: number) => {
    sizes.map((_size, i) => {
      sizes[i].checked = false;
    });
    selectTypographySize(sizes[index].size as never);
    sizes[index].checked = true;

    setSizes([...sizes]);
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <View
          style={[
            styles.row,
            {
              flex: 1,
              marginBottom: 20,
              paddingVertical: 10,
              borderColor: colors.onPrimaryContainer,
            },
          ]}
          key={makeKey(5)}
        >
          <Text type={'headline'} style={{ textAlign: 'center' }}>
            Font size
          </Text>
          <View style={[styles.column, { flex: 1 }]} key={makeKey(5)}>
            {sizes.map((size, index) => {
              const label = size.size.charAt(0).toUpperCase() + size.size.slice(1);
              return (
                <View
                  style={[styles.row, { flex: 2, borderColor: colors.onPrimaryContainer }]}
                  key={makeKey(5)}
                >
                  <Checkbox
                    label={label}
                    index={index}
                    value={size.checked}
                    onChangeValue={handleChange}
                  />
                </View>
              );
            })}
          </View>
        </View>
        <View
          style={[styles.row, { flex: 1, borderColor: colors.onPrimaryContainer }]}
          key={makeKey(5)}
        >
          <Text type={'headline'} style={{ textAlign: 'center' }}>
            Paleta de cores
          </Text>
          <View
            style={[
              styles.column,
              {
                flex: 1,
                flexGrow: 1,
                flexBasis: 0,
              },
            ]}
            key={makeKey(5)}
          >
            <FlatList
              style={{ marginTop: 20 }}
              key={makeKey(5)}
              data={colorsArray}
              renderItem={({ item, index }) => (
                <View
                  style={{
                    flex: 1,
                    alignItems: 'center',
                    flexGrow: 1,
                    flexBasis: 0,
                    margin: 4,
                    padding: 20,
                    borderWidth: 1,
                    backgroundColor: colorsArray[index][1],
                    borderColor:
                      colorsArray[index - 2] != undefined
                        ? colorsArray[index - 2][1]
                        : colorsArray[index][1],
                  }}
                >
                  <Text
                    style={{
                      color:
                        colorsArray[index + 1] != undefined
                          ? colorsArray[index + 1][1]
                          : colorsArray[index][1],
                    }}
                  >
                    {item[0]}
                  </Text>
                </View>
              )}
              //Setting the number of column
              numColumns={2}
              keyExtractor={(item) => item[0]}
            />
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: LAYOUT.CONTAINER.PADDING_HORIZONTAL,
  },
  row: {
    flex: 1,
    flexDirection: 'column',
    borderBottomWidth: 1,
    width: '100%',
  },
  column: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignContent: 'flex-end',
  },
  checkbox: {
    alignSelf: 'flex-start',
  },
  slider: {
    width: 300,
    opacity: 1,
    height: 100,
    marginTop: 10,
  },
});
