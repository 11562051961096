import * as React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { AboutScreen, AccountScreen, HomeScreen, NotFoundScreen } from '@src/screens';
import { useAuth } from '@src/hooks/useAuth';
import { Text, View } from '@src/components';
import { DrawerActions, NavigationProp, useNavigation } from '@react-navigation/native';

import { Pressable, StyleSheet } from 'react-native';
import { useColorMode } from '@src/hooks';
// import { IconDarkMode } from '@src/components/icons/dark';

export const LoggedMenuStack = () => {
  const Drawer = createDrawerNavigator<LoggedMenuStackParamList>();
  const { navigate, dispatch } =
    useNavigation<NavigationProp<RootStackParamList | SettingsStackParamList>>();
  const { signOut, user } = useAuth();
  const { colors, toggleColorMode, colorMode } = useColorMode();

  const handlerSignOut = () => {
    dispatch(DrawerActions.closeDrawer());
    signOut();
  };
  const handlerNavigate = (screen: string) => {
    navigate(screen);
  };

  const handlerColorMode = () => {
    toggleColorMode();
    dispatch(DrawerActions.closeDrawer());
  };

  const styles = StyleSheet.create({
    drawerContent: {
      backgroundColor: colors.outlineVariant,
    },
    drawerItem: {
      backgroundColor: colors.outlineVariant,
      color: colors.onPrimary,
      textAlign: 'justify',
      marginLeft: 10,
      alignContent: 'center',
      paddingVertical: 10,
      borderBottomWidth: 1,
      borderBottomColor: colors.outlineVariant,
    },
  });

  return (
    <Drawer.Navigator
      id={'LOGGED_MENU_STACK'}
      initialRouteName={'home'}
      screenOptions={{
        headerShown: false,
        drawerPosition: 'right',
        drawerType: 'back',
        drawerStatusBarAnimation: 'fade',
      }}
      defaultStatus={'closed'}
      drawerContent={() => {
        return (
          <View style={styles.drawerContent}>
            <Text
              type={'title'}
              style={{ ...styles.drawerItem, textAlign: 'center', fontWeight: 'bold' }}
            >
              {user?.email}
            </Text>
            <Pressable style={styles.drawerItem} onPress={() => handlerNavigate('Account')}>
              <Text style={{ color: styles.drawerItem.color }}>Perfil</Text>
            </Pressable>
            <Pressable style={styles.drawerItem} onPress={() => handlerColorMode()}>
              <Text style={{ color: styles.drawerItem.color, paddingHorizontal: 5 }}>
                {colorMode == 'dark' ? 'Desativar modo' : 'Ativar modo'} Escuro
              </Text>
            </Pressable>
            <Pressable style={styles.drawerItem} onPress={() => handlerNavigate('About')}>
              <Text style={{ color: styles.drawerItem.color }}>Sobre</Text>
            </Pressable>
            <Pressable style={styles.drawerItem} onPress={handlerSignOut}>
              <Text style={{ color: styles.drawerItem.color }}>Sair</Text>
            </Pressable>
          </View>
        );
      }}
    >
      <Drawer.Screen
        name="Home"
        component={HomeScreen}
        navigationKey={'HOME'}
        options={{ title: 'Home' }}
      />
      <Drawer.Screen name="Account" component={AccountScreen} navigationKey={'ACCOUNT'} />
      <Drawer.Screen name="About" component={AboutScreen} navigationKey={'SETTINGS'} />
      <Drawer.Screen name="NotFound" component={NotFoundScreen} />
    </Drawer.Navigator>
  );
};
