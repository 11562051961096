import React from 'react';
import { View, Text } from '@src/components';
import { layoutStyle } from '@src/styles';

export const HomeScreen = () => {
  return (
    <View style={layoutStyle.container}>
      <View style={layoutStyle.content}>
        <Text type={'headline'} style={layoutStyle.headline}>
          Home
        </Text>
        <Text type={'title'}>Laboratório de desenvolvimento usando React Native</Text>
      </View>
    </View>
  );
};
