/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState } from 'react';
import {
  Animated,
  Dimensions,
  Easing,
  ImageSourcePropType,
  PixelRatio,
  Pressable,
  StyleSheet,
} from 'react-native';
import { useColorMode } from '@src/hooks';
import appLogoWhite from '@assets/images/app-logo-white.png';
import appLogo from '@assets/images/app-logo.png';
import { Text, View } from '../core';
import { NavigationProp, useNavigation } from '@react-navigation/native';

interface ILogoTitle {
  title: string;
}

type Props = ILogoTitle;

export const LogoTitle = ({ title }: Props) => {
  const { navigate } = useNavigation<NavigationProp<RootStackParamList | SettingsStackParamList>>();
  const { colorMode, colors } = useColorMode();
  const spinValue = new Animated.Value(0);

  Animated.timing(spinValue, {
    toValue: 360,
    duration: 300000,
    easing: Easing.linear, // Easing is an additional import from react-native
    useNativeDriver: true, // To make use of native driver for performance
  }).start();

  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  const APP_LOGO_USE: ImageSourcePropType = colorMode == 'dark' ? appLogo : appLogoWhite;
  const WIDTH_WINDOW = Dimensions.get('window').width;
  const FONT_SIZE_TITLE = WIDTH_WINDOW >= 500 ? 24 : 8;
  let SIZE_APP_LOGO = WIDTH_WINDOW >= 500 ? 50 : 25;
  if (WIDTH_WINDOW < 400) {
    SIZE_APP_LOGO = 16;
  }

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'transparent',
    },
    imageContainer: {
      width: PixelRatio.getPixelSizeForLayoutSize(SIZE_APP_LOGO),
      height: PixelRatio.getPixelSizeForLayoutSize(SIZE_APP_LOGO),
      backgroundColor: 'transparent',
      aspectRatio: 1 * 1.4,
      zIndex: 1000,
      marginRight: 10,
    },
    appLogo: {
      resizeMode: 'cover',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignSelf: 'center',
    },
    appLogoHover: {
      resizeMode: 'cover',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignSelf: 'center',
      transform: [{ rotate: spin } as never],
    },
    title: {
      color: colors.onPrimary,
      fontSize: PixelRatio.getPixelSizeForLayoutSize(FONT_SIZE_TITLE),
    },
  });

  const handleronPress = () => {
    navigate('Home');
  };
  const [logoStyle, setlogoStyle] = useState(styles.appLogo);

  return (
    <Pressable
      style={styles.container}
      onPress={() => handleronPress()}
      onHoverIn={() => setlogoStyle(styles.appLogoHover)}
      onHoverOut={() => setlogoStyle(styles.appLogo)}
    >
      <View style={styles.imageContainer}>
        <Animated.Image style={logoStyle} source={APP_LOGO_USE} />
      </View>
      <Text type={'headline'} style={styles.title}>
        {title}
      </Text>
    </Pressable>
  );
};
