export type FontWeightType =
  | '500'
  | '400'
  | 'bold'
  | 'normal'
  | '100'
  | '200'
  | '300'
  | '600'
  | '700'
  | '800'
  | '900'
  | undefined;
export type PpropertiesType = {
  fontFamily?: string | undefined;
  fontSize?: number | undefined;
  fontStyle?: 'normal' | 'italic' | undefined;
  fontWeight: FontWeightType;
  letterSpacing?: number | undefined;
  lineHeight?: number | undefined;
};

export type TypographySizeKeyType = 'large' | 'medium' | 'small' | null;
export type TypographySizeType = {
  large: PpropertiesType;
  medium: PpropertiesType;
  small: PpropertiesType;
};

export type TypographyTextType = 'display' | 'headline' | 'body' | 'label' | 'title' | undefined;
export type TypographyType = {
  display: TypographySizeType;
  headline: TypographySizeType;
  body: TypographySizeType;
  label: TypographySizeType;
  title: TypographySizeType;
};

const typography: TypographyType = {
  display: {
    large: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 57,
      lineHeight: 64,
      letterSpacing: -0.25,
    },
    medium: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 45,
      lineHeight: 52,
      letterSpacing: 0,
    },
    small: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 36,
      lineHeight: 44,
      letterSpacing: 0,
    },
  },
  headline: {
    large: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 32,
      lineHeight: 40,
      letterSpacing: 0,
    },
    medium: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 28,
      lineHeight: 36,
      letterSpacing: 0,
    },
    small: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 24,
      lineHeight: 32,
      letterSpacing: 0,
    },
  },
  body: {
    large: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 16,
      lineHeight: 24,
      letterSpacing: 0.5,
    },
    medium: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 14,
      lineHeight: 20,
      letterSpacing: 0.25,
    },
    small: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 12,
      lineHeight: 16,
      letterSpacing: 0.4000000059604645,
    },
  },
  label: {
    large: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: 14,
      lineHeight: 20,
      letterSpacing: 0.10000000149011612,
    },
    medium: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: 12,
      lineHeight: 16,
      letterSpacing: 0.5,
    },
    small: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: 11,
      lineHeight: 16,
      letterSpacing: 0.5,
    },
  },
  title: {
    large: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 22,
      lineHeight: 28,
      letterSpacing: 0,
    },
    medium: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: 16,
      lineHeight: 24,
      letterSpacing: 0.15000000596046448,
    },
    small: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: 14,
      lineHeight: 20,
      letterSpacing: 0.10000000149011612,
    },
  },
};

export const Typography = typography;
