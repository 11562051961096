import { LAYOUT } from '@src/constants/layout';
import { StyleSheet } from 'react-native';

export const layoutStyle = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: LAYOUT.CONTAINER.PADDING_HORIZONTAL,
  },
  content: {
    flex: 1,
  },
  headline: {
    textAlign: 'left',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    paddingVertical: 15,
    marginBottom: 10,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
  },
  column: {
    flex: 1,
    flexDirection: 'column',
    padding: 10,
  },
});
